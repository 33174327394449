/* eslint-disable prettier/prettier */
import React from "react"
import { graphql } from "gatsby"

import GlobalStateProvider from "../context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/about-us/hero"
import About from 'components/sections/about-us/about'
import Team from "components/sections/about-us/team"
import Banner from "components/sections/common/banner"
import Investors from "components/sections/about-us/investors"


import Security from "components/sections/common/security"
import Trial from "components/common/trial"
import { seoTitleSuffix } from "config"

const AboutPage = ({ data }) => {
  const { frontmatter } = data.aboutUs.edges[0].node

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout footerContent={data.footer.edges} headerContent={data.header.edges}>
        <SEO
          frontmatter={frontmatter}
        />
        <Hero content={data.hero.edges} />
        <About content={data.mission.edges} />
        <About content={data.story.edges} reversed/>
				<Team content={data.team.edges} />
        <Investors content={data.investors.edges} />
        <div className="gradient">
          <Banner content={data.banner.edges} />
        </div>
      </Layout>
    </GlobalStateProvider>
  )
}


export default AboutPage

export const pageQuery = graphql`
  {
    aboutUs: allMdx(filter: { fileAbsolutePath: { regex: "/about-us/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/about-us/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            teamPhoto {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
            mdxbgImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
        }
      }
    }
    mission: allMdx(filter: { fileAbsolutePath: { regex: "/about-us/about/mission/" } }) {
      edges {
        node {
          body
          frontmatter {
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            flagImg {
              childImageSharp {
                gatsbyImageData(width: 50, layout: FIXED, quality: 90)
              }
            }
          }
        }
      }
    }
    story: allMdx(filter: { fileAbsolutePath: { regex: "/about-us/about/story/" } }) {
      edges {
        node {
          body
          frontmatter {
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            docsImg {
              childImageSharp {
                gatsbyImageData(width: 50, layout: FIXED, quality: 90)
              }
            }
          }
        }
      }
    }
    team: allMdx(filter: { fileAbsolutePath: { regex: "/about-us/team/" } }) {
      edges {
        node {
          exports {
            items {
              name
              position
              linkedin
              twitter
              descriptions
              photo {
                childImageSharp {
                  gatsbyImageData(quality: 90)
                }
              }
            }
          }
          frontmatter {
            title
            subtitle
          }
        }
      }
    }
    investors: allMdx(filter: { fileAbsolutePath: { regex: "/about-us/investors/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            shownItems
            items {
              year
              text
            }
            logos {
              alt
              logo {
                childImageSharp {
                  gatsbyImageData(height: 120, formats: [WEBP, AVIF], quality: 90)
                }
              }
              logoHover {
                childImageSharp {
                  gatsbyImageData(width: 120, formats: [WEBP, AVIF], quality: 90)
                }
              }
              link
            }
          }
        }
      }
    }
    banner: allMdx(filter: { fileAbsolutePath: { regex: "/about-us/banner/" } }) {
      edges {
        node {
          frontmatter {
            title
            buttonTitle
            link
            external
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
